<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="row mt-4">
			<div class="col-12 d-flex">
				<div class="h2 text-muted py-3">
					{{ translate('order_successfully_placed') }}
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<ul class="list-group w-100">
					<li class="list-group-item">
						<div class="row">
							<div
								id="content"
								class="col-sm-12 text-center"
								style="margin: 30px 0 60px;">
								<i class="fas fa-check-circle fa-10x success-icon" />
								<div class="success-message margin-20">
									<h3
										class="mt-4"
										v-html="translate('checkout_success_subtittle')" />
									<hr>
									<p v-html="translate('checkout_success_body', {support:companyEmail, support1:companyEmail})" />
								</div>
								<div class="clearfix text-center">
									<div class="container">
										<div class="col-xs-12">
											<div class="text-center">
												<b-button
													variant="primary"
													style="width: 200px"
													:class="!['xs'].includes(windowWidth) ? 'mr-4' : 'mb-2'"
													@click="$router.push({ name: 'Home' })">
													{{ translate('go_to_dashboard') }}
												</b-button>
												<b-button
													variant="primary"
													style="width: 200px"
													@click="$router.push({ name: 'Store' })">
													{{ translate('go_to_store') }}
												</b-button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-4 ml-auto my-4" />
		</div>
	</div>
</template>

<script>
import { Purchase, Validations } from '@/translations';
import DocumentTitle from '@/mixins/DocumentTitle';
import WindowSizes from '@/mixins/WindowSizes';
import { GOVERNMENT_DOC_COUNTRIES } from '@/settings/Country';
import { ORDER_VERIFICATION_PHONES } from '@/settings/Purchase';

export default {
	name: 'GiftOrderSuccess',
	messages: [Purchase, Validations],
	mixins: [DocumentTitle, WindowSizes],
	props: {
		paymentMethodName: {
			type: String,
			default: '',
		},
		verificationRequired: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			alert: new this.$Alert(),
			companyEmail: process.env.VUE_APP_COMPANY_EMAIL,
		};
	},
	async mounted() {
		// Uncomment if checkmo is enabled for gift orders
		// if (this.paymentMethodName === 'checkmo') {
		// 	const alertTrans = `cash_payments_popup_description${NO_DAY_LIMIT_CASH_PAYMENT.includes(this.country) ? '_no_day_limit' : ''}`;
		// 	this.alert.success(this.translate('cash_payments_popup_title'), this.translate(alertTrans, { displayedLink: this.companyEmail, supportLink: this.companyEmail }), true);
		// }
		await this.showOrderVerificationMessage();
		await this.showGovernmentDocMessage();
	},
	methods: {
		showGovernmentDocMessage() {
			const giftCountry = localStorage.getItem('gift_country');

			if (giftCountry) {
				localStorage.removeItem('gift_country');
			}

			const country = giftCountry ?? this.country;
			if (GOVERNMENT_DOC_COUNTRIES.includes(country)) {
				const alertConfig = {
					config: {
						icon: 'info',
						showCancelButton: false,
						isHtml: true,
					},
				};
				return this.alert.confirmation(this.translate('government_doc_title'), this.translate('government_doc_text'), alertConfig, true);
			}
			return Promise.resolve();
		},
		showOrderVerificationMessage() {
			if (this.verificationRequired) {
				const phoneNumbers = [].concat(ORDER_VERIFICATION_PHONES);
				const last = phoneNumbers.pop();
				const phoneList = phoneNumbers.length > 0
					? `${phoneNumbers.join(', ')} ${this.translate('or')} ${last}`
					: last;

				return this.alert.success(this.translate('verbal_verification_popup_title'), this.translate('verbal_verification_existing_popup_description', {
					companyName: process.env.VUE_APP_TITLE,
					phoneNumber: phoneList,
				}), true);
			}
			return Promise.resolve();
		},
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (['NewInternationalShopping', 'NewGiftOrder'].includes(from.name) && to.query.wallet_address) {
				vm.$router.replace({ name: 'CryptoPaymentsDetails', params: { wallet_address: to.query.wallet_address } });
			} else if (!['NewInternationalShopping', 'NewGiftOrder'].includes(from.name)) {
				vm.$router.replace({ name: 'Login' });
			}
		});
	},
};
</script>
<style scoped>
.success-icon::before {
	color: #63D13E !important;
}
</style>
